import { refreshCognitoToken } from './refreshTokenService.js';
import { getURLFromEnv, getRedirectUrl } from './getOriginURL.js';
import { decodeIdTokenAndGetEmail, setAccessTokenExpiry } from './authUtils.js';

export async function handleTokenRefreshAndRedirect(route) {
  try {
    const auth = await refreshCognitoToken();

    const [idTokenErr, email] = decodeIdTokenAndGetEmail(auth.idToken);

    if (idTokenErr) {
      throw idTokenErr;
    }

    const expiresAt = Date.now() + 1000 * auth.expiresIn;
    setAccessTokenExpiry(expiresAt, auth.expiresIn);

    const location = getRedirectUrl(route) || (await getURLFromEnv(email));
    window.location.href = location;
    return await new Promise(() => {});
  } catch (e) {
    console.log(e);
    return Promise.resolve();
  }
}
