import api from '@/services/api';

export function refreshCognitoToken() {
  return api.post(
    'tokens/refresh',
    {
      clientId: process.env.VUE_APP_COGNITO_APP_CLIENT,
      userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    },
    { withCredentials: true },
  );
}
