import { to } from 'await-to-js';
import { errorLoginTrack } from './authenticationTrack/authenticationTrackService';
import { amplifySignIn, amplifySignOut } from './amplifyService.js';
import { getError } from './errorsService.js';
import { bootstrapAuthToken } from './bootstrapAuthToken.js';
import { migrateSignin } from './migrateSigninService.js';
import { hasRequiredTotpConfiguration } from './cognitoService.js';

export async function signInAndRedirect(username, password, app) {
  let err = null;
  let data = null;

  await to(amplifySignOut());
  const [errorMigrate] = await to(migrateSignin(username, password));

  if (errorMigrate) {
    errorLoginTrack({
      email: username,
      isCognito: true,
      properties: {
        step: 'migrateSignin',
        error: errorMigrate.response?.data,
      },
    });
    return Promise.reject(getError(errorMigrate.response?.data?.code));
  }

  [err, data] = await to(amplifySignIn(username, password));
  if (err) {
    errorLoginTrack({
      email: username,
      isCognito: true,
      properties: {
        step: 'cognitoSignin',
        error: err,
      },
    });
    return Promise.reject(getError(err?.name));
  }
  const resolveChallenges = [
    'CONFIRM_SIGN_IN_WITH_SMS_CODE',
    'CONFIRM_SIGN_IN_WITH_TOTP_CODE',
    'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
    'CONFIRM_SIGN_UP',
  ];
  if (resolveChallenges.includes(data.nextStep?.signInStep)) {
    return Promise.resolve(data);
  }

  const [, dataRequiredTotp] = await to(hasRequiredTotpConfiguration());

  if (dataRequiredTotp?.hasRequiredTotpConfiguration && !app) {
    return Promise.resolve(dataRequiredTotp);
  }

  [err, data] = await to(bootstrapAuthToken(app));

  if (err) {
    errorLoginTrack({
      email: username,
      isCognito: true,
      properties: {
        step: err.step,
        error: err.message,
      },
    });
    return Promise.reject(getError(err?.message?.code));
  }

  return Promise.resolve(data);
}
