<template>
  <auth-refresh-wrapper>
    <router-view></router-view>
  </auth-refresh-wrapper>
</template>

<script>
import AuthRefreshWrapper from '@/components/authRefreshWrapper/AuthRefreshWrapper';

export default {
  name: 'App',
  components: {
    AuthRefreshWrapper,
  },
};
</script>

<style>
body {
  margin: 0 auto;
}
</style>
