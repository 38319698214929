import { to } from 'await-to-js';
import {
  errorConfirmMfaSigninTrack,
  successConfirmMfaSigninTrack,
} from './authenticationTrack/authenticationTrackService';
import { bootstrapAuthToken } from './bootstrapAuthToken';
import { amplifyConfirmSignin, amplifyRememberDevice } from './amplifyService';
import { getError } from './errorsService';

export async function confirmSignin(code, email, rememberDevice, app) {
  const [err] = await to(amplifyConfirmSignin(code));

  if (err) {
    errorConfirmMfaSigninTrack({
      email,
      properties: {
        step: 'cognitoConfirmSignIn',
        error: err,
      },
    });
    return Promise.reject(getError(err.name));
  }

  if (rememberDevice) {
    await amplifyRememberDevice();
  }

  const [error, data] = await to(bootstrapAuthToken(app));

  if (error) {
    errorConfirmMfaSigninTrack({
      email,
      properties: {
        step: error.step,
        error: error.message,
      },
    });
    return Promise.reject(getError(error?.message?.code));
  }

  successConfirmMfaSigninTrack({
    email,
    properties: {
      step: 'redirectToApp',
      jwt: data?.jwt,
    },
  });

  return Promise.resolve(data);
}
