import https from 'https';
import api from '@/services/api';

export function bootstrapJWT(payload) {
  return api.post(`${process.env.VUE_APP_SERVER_PATH}/session`, payload, {
    httpsAgent: new https.Agent({
      rejectUnauthorized: process.env.VUE_APP_ENV === 'production',
    }),
    withCredentials: true,
  });
}
