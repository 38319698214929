export default class SecureStorage {
  storageObject = {};

  isRefreshTokenKey(key) {
    return key?.toLowerCase().includes('refreshtoken');
  }

  async setItem(key, value) {
    if (this.isRefreshTokenKey(key)) {
      this.storageObject[key] = value;
    } else {
      localStorage.setItem(key, value);
    }
  }

  async getItem(key) {
    return this.isRefreshTokenKey(key) ? this.storageObject[key] || null : localStorage.getItem(key);
  }

  async removeItem(key) {
    if (this.isRefreshTokenKey(key)) {
      delete this.storageObject[key];
    } else {
      localStorage.removeItem(key);
    }
  }

  async clear() {
    this.storageObject = {};
    localStorage.clear();
  }
}
