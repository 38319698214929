import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import { getError } from './errorsService';

export function decodeIdTokenAndGetEmail(idToken) {
  const decoded = jwtDecode(idToken);

  if (!decoded?.userId) {
    const error = { error: JSON.stringify({ ...getError('MissingTokenDataAfterLogin') }) };
    return [error, null];
  }

  return [null, decoded?.email];
}

export function setAccessTokenExpiry(expiresAt, expiresIn) {
  if (expiresAt) {
    Cookies.set('ca-access-token-expiry', expiresAt.toString(), {
      domain: process.env.VUE_APP_COOKIE_DOMAIN,
      secure: window.location.protocol === 'https:',
      expires: expiresIn / (60 * 60 * 24),
    });
  }
}
