import { to } from 'await-to-js';
import {
  errorValidateTotpCodeTrack,
  successValidateTotpCodeTrack,
} from './authenticationTrack/authenticationTrackService';
import { bootstrapAuthToken } from './bootstrapAuthToken';
import { getError } from './errorsService';
import { amplifyVerifyTOTPSetup, amplifyUpdateMFAPreference } from './amplifyService';

export async function confirmTotpToken(code, email) {
  const [totpErr] = await to(amplifyVerifyTOTPSetup(code));

  if (totpErr) {
    errorValidateTotpCodeTrack({
      email,
      properties: {
        step: 'verifyTotpToken',
        error: totpErr,
      },
    });
    return Promise.reject(getError(totpErr.name));
  }

  await to(amplifyUpdateMFAPreference({ totp: 'PREFERRED' }));
  const [err, data] = await to(bootstrapAuthToken());

  if (err) {
    errorValidateTotpCodeTrack({
      email,
      properties: {
        step: err.step,
        error: err.message,
      },
    });
    console.log(err);
    return Promise.reject(getError(err?.message?.code));
  }

  successValidateTotpCodeTrack({
    email,
    properties: {
      step: 'redirectToApp',
      jwt: data?.jwt,
    },
  });

  return Promise.resolve(data);
}
