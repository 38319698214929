import { to } from 'await-to-js';
import { getError } from './errorsService.js';
import { amplifyResendSignUpCode } from './amplifyService.js';

export async function amplifyResendCode(email) {
  const [err, result] = await to(amplifyResendSignUpCode(email));

  if (err?.name) {
    console.log(err);
    return Promise.reject(getError(err.name));
  }

  return Promise.resolve(result);
}

function paddedFormat(num) {
  return num < 10 ? `0${num}` : num;
}

export function getFormattedTime(seconds) {
  const min = parseInt(seconds / 60);
  const sec = parseInt(seconds % 60);

  return `${paddedFormat(min)}:${paddedFormat(sec)}`;
}
