<template>
  <ds-form
    :submit-action="submitAction"
    @submit-error="onSubmitError"
    @submit-success="onSubmitSuccess"
    @update:isValid="setFormValid">
    <ds-row content-vertical-align="center">
      <ds-col>
        <ds-heading text="Você precisa redefinir sua senha" size="md" />
      </ds-col>
      <ds-col>
        <ds-p> Para continuar, por favor, crie uma nova senha. Isso garantirá a segurança da sua conta. </ds-p>
      </ds-col>
      <ds-col>
        <ds-field label="Nova senha">
          <ds-password-input v-model="password" class="ds-u-color--grey-darkest" required validate />
        </ds-field>
        <ds-field label="Confirme sua senha">
          <ds-password-input v-model="confirmPassword" :custom-validations="customValidations" required />
        </ds-field>
      </ds-col>
    </ds-row>
    <ds-row>
      <ds-col size="6">
        <ds-submit-button :disabled="!isFormValid" full-width>Criar nova senha</ds-submit-button>
      </ds-col>
    </ds-row>
  </ds-form>
</template>

<script>
import { toasterService } from '@contaazul/design-system';
import { amplifyConfirmSignin } from '@/services/amplifyService.js';
import {
  triedToForceChangePasswordTrack,
  successForceChangePasswordTrack,
  errorForceChangePasswordTrack,
} from '@/services/authenticationTrack/authenticationTrackService';
import { handleRequiredParams } from '@/services/amplifyHelperService/amplifyHelperService.js';

export default {
  name: 'PasswordRequired',
  data() {
    return {
      password: '',
      confirmPassword: '',
      nextStep: null,
      email: '',
      isFormValid: false,
      customValidations: [
        {
          message: 'As senhas inseridas não coincidem',
          valid: value => this.password === value,
        },
      ],
    };
  },
  mounted() {
    this.nextStep = this.$route.params.nextStep;
    this.email = this.$route.params.email;
    if (!this.nextStep) {
      this.redirectToLogin();
    }
  },
  methods: {
    submitAction() {
      const requiredParams = handleRequiredParams(this.nextStep.missingAttributes, this.email);
      triedToForceChangePasswordTrack({
        email: this.email,
        properties: {
          requiredParams,
        },
      });
      return amplifyConfirmSignin(this.password, requiredParams);
    },
    onSubmitError(error) {
      errorForceChangePasswordTrack({
        email: this.email,
        properties: {
          error,
        },
      });
      this.redirectToLogin();
      toasterService.error({
        title: 'Erro ao alterar senha',
        content: 'Faça o login novamente para continuar com a alteração de senha',
      });
    },
    onSubmitSuccess() {
      successForceChangePasswordTrack({
        email: this.email,
      });
      this.redirectToLogin();
      toasterService.success({
        title: 'Senha alterada com sucesso',
        content: 'Para acessar sua conta, entre com sua nova senha',
      });
    },
    setFormValid(value) {
      this.isFormValid = value;
    },
    redirectToLogin() {
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>
