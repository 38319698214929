<template>
  <div v-if="submitted">
    <ds-row>
      <ds-col>
        <ds-illustration name="email" height="120px" />
      </ds-col>
    </ds-row>
    <ds-row>
      <ds-col>
        <ds-heading text="Verifique seu e-mail" size="lg" />
      </ds-col>
    </ds-row>
    <ds-row>
      <ds-col>
        <ds-p>
          Enviamos o ID de suporte para <ds-text weight="bold">{{ email }}.</ds-text> Confira sua caixa de entrada.
        </ds-p>
      </ds-col>
    </ds-row>
    <ds-row>
      <ds-col size="auto">
        <ds-link href="#/">Voltar para o início</ds-link>
      </ds-col>
    </ds-row>
  </div>
  <div v-else>
    <div>
      <ds-heading text="Recupere seu ID de suporte" size="lg" />
      <ds-p class="ds-u-margin-top">Digite o e-mail cadastrado na Conta Azul Pro para continuar.</ds-p>
    </div>
    <ds-form
      class="ds-u-margin-top--lg"
      :submit-action="submit"
      :is-valid.sync="isValid"
      :error-message="errorMessage"
      @submit-success="submitted = true"
      @submit-error="onSubmitError">
      <ds-row>
        <ds-col>
          <ds-field label="E-mail" class="ds-u-margin-bottom--none">
            <ds-email-input v-model="email" required />
          </ds-field>
        </ds-col>
      </ds-row>
      <ds-row>
        <ds-col size="auto">
          <ds-button href="#/">Cancelar</ds-button>
        </ds-col>
        <ds-col size="fill" />
        <ds-col size="auto">
          <ds-submit-button theme="primary" :disabled="!isValid">Recuperar ID</ds-submit-button>
        </ds-col>
      </ds-row>
    </ds-form>
  </div>
</template>

<script>
import { requestSupportCode } from '@/resources/supportCode';

export default {
  name: 'RecoverSupportID',
  data() {
    return {
      email: '',
      submitted: false,
      isValid: false,
      errorMessage: null,
    };
  },
  methods: {
    submit() {
      return requestSupportCode(this.email);
    },
    onSubmitError() {
      this.errorMessage = {
        title: 'Erro ao recuperar o ID de suporte',
        description: 'Ocorreu um erro ao recuperar o ID de suporte. Por favor, tente novamente.',
      };
    },
  },
};
</script>
