import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import {
  signIn,
  signOut,
  fetchAuthSession,
  getCurrentUser,
  confirmSignIn,
  rememberDevice,
  setUpTOTP,
  verifyTOTPSetup,
  fetchMFAPreference,
  updateMFAPreference,
  resendSignUpCode,
  resetPassword,
  confirmResetPassword,
} from 'aws-amplify/auth';
import SecureStorage from './SecureStorage';

const storageInstance = new SecureStorage();

export function getStorageInstance() {
  return storageInstance;
}

function configure() {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
        userPoolClientId: process.env.VUE_APP_COGNITO_APP_CLIENT,
      },
    },
  });

  cognitoUserPoolsTokenProvider.setKeyValueStorage(getStorageInstance());
}

export function amplifySignIn(username, password) {
  configure();
  return signIn({ username, password });
}

export function amplifySignOut() {
  configure();
  return signOut();
}

export function amplifyFetchUserSession() {
  return fetchAuthSession();
}

export function amplifyGetCurrentUser() {
  return getCurrentUser();
}

export function amplifyConfirmSignin(challengeResponse, userAttributes) {
  return confirmSignIn({
    challengeResponse,
    options: {
      userAttributes,
    },
  });
}

export function amplifyRememberDevice() {
  return rememberDevice();
}

export function amplifySetupTotp() {
  return setUpTOTP();
}

export function amplifyVerifyTOTPSetup(code) {
  return verifyTOTPSetup({ code });
}

export function amplifyFetchMFAPreference() {
  return fetchMFAPreference();
}

export function amplifyUpdateMFAPreference(preference) {
  return updateMFAPreference(preference);
}

export function amplifyResendSignUpCode(username) {
  return resendSignUpCode({ username });
}

export function amplifyResetPassword(username) {
  configure();
  return resetPassword({ username });
}

export function forgotPasswordChange(username, confirmationCode, newPassword) {
  configure();
  return confirmResetPassword({
    username,
    confirmationCode,
    newPassword,
  });
}
