<template>
  <div v-if="isLoading" class="loader">
    <ds-loader />
  </div>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script>
import { handleTokenRefreshAndRedirect } from '@/services/authTokenHandler.js';

export default {
  name: 'AuthRefreshWrapper',
  data() {
    return {
      isLoading: false,
    };
  },
  async created() {
    if (this.$route.name === 'Login') {
      this.isLoading = true;
      try {
        await handleTokenRefreshAndRedirect(this.$route);
      } catch (error) {
        console.error('Token refresh failed:', error);
      } finally {
        this.isLoading = false;
      }
    }
  },
};
</script>
<style scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
